<template>
    <div class="server-page">
        <div class="banner-outer">
            <div class="banner">
                <div
v-if="server.status && server.status.favicon && server.status.favicon.replace('data:image/png;base64,', '') !== ''"
                     class="banner-image">
                    <img :src="server.status.favicon" alt="Server favicon">
                </div>
                <div class="banner-inner">
                    <div
                            v-if="server.status && server.status.description"
                            class="banner-motd"
                            v-html="server.status.description"
                    />
                </div>
            </div>
        </div>
        <div class="server-info">
            <div class="server-info-title">
                <h1>
                    {{ server.minecraftTitle }} Server IP
                </h1>
            </div>
        </div>
        <div class="line"/>
        <h2>Server Details</h2>
        <div class="server-details">
            <table>
                <tr>
                    <td class="title">IP Address</td>
                    <td class="content">
                        <div
class="ip-chip"
                             :class="!server.status || !server.status.uptime ? 'ip-chip-offline' : ''">
                            <button v-tippy="'Copy to clipboard'" @click="copyIP">
                                {{
                                    server.ip + (server.port !== 25565 ? ":" + server.port : "")
                                }}
                                <img
                                        alt="Copy IP"
                                        src="/assets/icons/copy_white.svg"
                                >
                            </button>
                        </div>
                    </td>
                </tr>
                <tr v-if="server.bedrock_ip && (server.bedrock_status && server.bedrock_status.uptime)">
                    <td class="title">Bedrock IP</td>
                    <td class="content">
                        <div class="ip-chip">
	                        <button v-tippy="'Copy to clipboard'" @click="copyIP">
                                {{
                                    server.bedrock_ip + (server.bedrock_port !== 19132 ? ":" + server.bedrock_port : "")
                                }}
                                <img
                                        alt="Copy IP"
                                        src="/assets/icons/copy_white.svg"
                                >
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="title">Players</td>
                    <td class="content">
                        {{
                            server.status &&
                            server.status.uptime &&
                            server.status.players &&
                            server.status.players.online
                                    ? server.status.players.online
                                    : 0
                        }}/{{
                            server.status &&
                            server.status.uptime &&
                            server.status.players &&
                            server.status.players.max
                                    ? server.status.players.max
                                    : 0
                        }}
                    </td>
                </tr>
                <tr>
                    <td class="title">Version</td>
                    <td class="content">
                        <div v-if="server.status && server.status.uptime && server.status.version" class="version">
                            {{ server.status.version.name.replaceAll(/§.{1}/g, "") }}
                        </div>
                        <div v-else class="version version-offline">
                            OFFLINE
                        </div>
                    </td>
                </tr>
                <tr v-if="server.user && server.user !== 'optimus'">
                    <td class="title">Owner</td>
                    <td class="content">
                        {{ server.user }}
                    </td>
                </tr>
                <tr v-if="server.website_url">
                    <td class="title">Website</td>
                    <td class="content">
                        <NuxtLink
                                :href="(/https?:\/\//).test(server.website_url.trim()) ? server.website_url.trim() : 'https://' + server.website_url.trim()"
                                target="_blank"
                                rel="nofollow"
                        >
                            {{ server.website_url }}
                        </NuxtLink>
                    </td>
                </tr>
            </table>
        </div>
        <h2>Did you like this server?</h2>
        <div class="buttons">
            <button class="vote" @click="vote">Vote</button>
            <button class="favourite" @click="favourite">
                {{
                    isFavourite ? "Unfavorite" : "Favorite"
                }}
            </button>
        </div>
        <h3>Server Tags</h3>
        <div class="tags">
            <NuxtLink
                    v-for="tag in tags"
                    :key="tag.name"
                    :href="'/server-tags/' + tag.urlname"
            >
                {{ tag.name }}
            </NuxtLink>
        </div>
        <template v-if="server.description">
            <h3>Description</h3>
            <div class="description">
	            <template v-for="(line, index) in splitDescription">
		            {{ line }}
		            <br v-if="index !== splitDescription.length - 1" />
	            </template>
            </div>
        </template>
        <h3>Statistics</h3>
        <div v-if="playersChartData" class="stats-chart">
            <h4>Total Users</h4>
            <LazyServerPlayersChart :players-chart="playersChartData"/>
        </div>
    </div>
    <div class="views">
        <span>Viewed {{ hit_count }} times</span>
    </div>
    <NuxtLink
            v-if="isAdminCookie || user && user.username === server.user"
            :to="'/server/' + server._id + '/edit'"
    >
        <button class="edit">Edit</button>
    </NuxtLink>
    <FooterServerFAQ :server="server" :tags="tags"/>
</template>

<script setup>
import {notify} from "@kyvg/vue3-notification";
import {titleEncode} from "~/utils/helpers.js";
import {autoToHTML} from "@sfirew/minecraft-motd-parser";
import VotePopup from '~/components/popups/VotePopup.vue';

const {user} = useUser();

defineComponent({
    name: "ServerPage"
});
const id = useRoute().params.id.split("-").slice(-1)[0];

const {data: server} = await useFetch("/api/server/" + id, {
    transform: (data) => {
        if (data.status && typeof data.status.description === "string") {
            data.status.description = data.status.description.replace("§R", "\n");
        }
        if (data.title) {
            data.minecraftTitle = data.title + (data.title.toLowerCase().includes("minecraft") ? "" : " Minecraft");
        }

        return data;
    },
});

// If server title and ip are empty, they don't exist
if (!server.value || !server.value.title || !server.value.ip) {
    throw createError({
        statusCode: 410,
        statusMessage: 'Not exist'
    })
}

const isAdminCookie = useCookie("is_admin");

const meta = {
    title: `${server.value.title} Server IP - Minecraft IP List`,
    ogTitle: `${server.value.title} Server IP - Minecraft IP List`,
    description: `All details for the Minecraft server ${server.value.title}. Find the server IP Address, player count, server website, and server statistics here.`,
    ogDescription: `All details for the Minecraft server ${server.value.title}. Find the server IP Address, player count, server website, and server statistics here.`,
    keywords: `${server.value.title.toLowerCase()} minecraft server ip address, ${server.value.title.toLowerCase()} minecraft server, join ${server.value.title.toLowerCase()} minecraft server`,
    ogImage: server.value.status && server.value.status.favicon ? server.value.status.favicon : "",
};

useServerSeoMeta(meta);
useSeoMeta(meta);

if (server.value.status && server.value.status.description) {
    server.value.status.description = autoToHTML(server.value.status.description);
}

const splitDescription = server.value.description.split(/<br ?\/?>|\\n/);

const hit_count = ref(server.value.hit_count);

const playersChartData = ref(server.value.status_history);

onMounted(async () => {
    const current = window.location.pathname;
    setTimeout(() => {
        if (current !== window.location.pathname) {
            return;
        }
        $fetch("/api/server/" + id + "/view", {
            method: "POST",
            onResponse(context) {
                if (context.response.status === 208) {
                    return;
                }

                hit_count.value++;
            },
        });
    }, 3000);

    isFavourite.value = (await $fetch("/api/server/" + id + "/favourite")).favourite;
});

const isFavourite = ref(false);

const favourite = async () => {
    isFavourite.value = !isFavourite.value;

    const response = await $fetch("/api/server/" + id + "/favourite", {
        method: "POST",
        query: {
            inc: isFavourite.value,
        },
        ignoreResponseError: true,
    });

    if (response.status) {
        notify({
            type: "success",
            title: isFavourite.value ?
                "Listing added to favorites" : "Listing removed from favorites"
        })
    } else if (response.error) {
        notify({
            type: "error",
            title: response.error
        })
        isFavourite.value = !isFavourite.value;
    } else {
        notify({
            type: "error",
            title: "An unknown error occurred"
        })
        isFavourite.value = !isFavourite.value;
    }
};

const {setPopup} = usePopup();

const vote = async () => {
    setPopup(VotePopup);

    // add /vote to url
    await useRouter().push(`/server/${titleEncode(server.value.title, server.value.custom_title_url)}-${server.value._id}/vote`);
};
const tags = server.value.tags;

useServerHead({
    link: [
        {
            rel: "canonical",
            href: "https://www.minecraftiplist.com" + useRoute().path
        },
    ],
});
</script>

<style lang="scss">
.banner-motd {
  display: block;
  width: fit-content;
  margin: 0 auto;
  text-align: center;

  &,
  & > span {
    overflow: hidden;
    white-space: nowrap;
  }

  & > * {
    font-family: Minecraft;
    font-size: 20px;
    min-width: 2px;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.banner-motd br {
  display: flex;
}
</style>

<style lang="scss" scoped>
$greenColor: #25855a;

@font-face {
  font-family: "Minecraft";
  src: url("/fonts/Minecraft.ttf");
}

.ip-chip {
  button {
    background-color: #805ad5;
    padding: 0.5em 0.75em;
    color: white;
    font-size: 0.85rem;

    white-space: nowrap;
    display: flex;
    align-items: center;

    border: none;
    border-radius: 6px;
    cursor: pointer;

    img {
      margin-left: 0.5em;
      width: 1em;
      height: 1em;
    }

    &:hover,
    &:focus {
      background-color: #6b46c1;
    }
  }
}

.banner-outer {
  margin-top: 1em;
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  .banner {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABVQTFRFIiIiGxsbHRYRFg8KLiEXJhsTHhUP7mNYCwAAAQFJREFUeNrsmDEOwzAMA92myf+fXMDqwOBAoXNELg1k2eeBsFSta+vYOkUVr+8DukSMlD5bK4ABgPph0trihpKL8HIBzAFwAy1L4/Y5AcwBuGV+q5Xd0cwMYA5AzcdUVxT7p/Bm0wDGAPrSqJdwx/0OlXIbwByAK3g84rXFa2kR1UgAEwBu2WH+kZo4gDkAmvW95dosffK0oLL1D2ACwG1jI+7aL8YDmAxwQ4RqpE7IHWprcgAPBfTjp36VBmXTH8AEAIeVNF/Z1JVGN/S8te8BPBrA1pyF0xnRja40J4AJAB0QcHDQt+Zs/WnfAOYANFX/2vXPWUXqcm41gAGArwADANYDrvGB0WJfAAAAAElFTkSuQmCC");
    background-repeat: repeat;

    width: 912px;
    height: 108px;

    display: flex;

    transition: none;
    position: relative;

    border-radius: 6px;

    @media (max-width: 912px) {
      width: 100%;
      height: 100px;
    }

    &:hover,
    &:focus {
      background: #000;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid #808080;
        box-sizing: border-box;
        border-radius: 6px;
      }
    }

    .banner-image {
      height: 100%;

      display: flex;
      align-items: center;
      margin: 0 1em;

      img {
        height: 80%;
      }

      @media (max-width: 768px) {
        margin: 0.25em;
        height: 0;
      }
    }

    .banner-inner {
      color: white;
      font-size: 1.2rem;
      width: 100%;
      display: flex;
      align-items: center;

      &:not(.banner-motd) {
        margin-left: 1em;
      }
    }
  }
}

.server-info {
  margin-top: 1em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;
  gap: 0.5em;

  color: #4a5568;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .server-info-title {
    width: 100%;
    font-weight: bold;

    img {
      height: 3em;
    }

    h1 {
      font-size: 1.35rem;
      margin-left: 0.5em;
      text-align: center;
      margin-bottom: 0.15em;
    }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 0.75em;

      img {
        float: left;
        margin-right: 0.5em;
      }

      h1 {
        margin: 0;
        font-size: 1.1rem;
      }
    }
  }
}

.line {
  width: 85%;
  height: 2px;
  background-color: #cbd5e0;
  margin: 1em auto 0 auto;
}

h2,
h3 {
  color: #4a5568;
  position: relative;
  width: fit-content;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #656566;
    bottom: -0.15em;
    left: 0;
  }
}

h2 {
  font-size: 1.35rem;
}

h3 {
  font-size: 1.2rem;
}

.server-details {
  table {
    position: relative;
    border-spacing: 0;

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    color: #4a5568;
    border: none;

    tr {
      display: grid;
      grid-template-columns: 16% 84%;
      min-height: 2.5em;

      @media (max-width: 768px) {
        grid-template-columns: 25% 75%;
      }
    }

    td {
      font-size: 1.1rem;
      vertical-align: center;
      padding-top: 0.5em;
    }

    td.title {
      font-weight: bold;
      padding-right: 1em;
      // vertically center
      display: flex;
      align-items: center;
    }

    @media (max-width: 480px) {
      tr {
        grid-template-columns: 27% 75%;
      }

      td.title {
        font-size: 0.9rem;
        padding-right: 0;
      }
    }

    td.content {
      display: flex;
      align-items: center;
    }

    .ip-chip {
      button {
        background: $greenColor;
        width: fit-content;
        padding: 0.5em 1em;
        justify-content: center;
        font-weight: bold;
      }
    }

    .version {
      background: $greenColor;
      width: fit-content;
      padding: 0.3em 0.6em;
      font-size: 0.8rem;
      color: white;
      border-radius: 6px;
    }

    a {
      text-decoration: none;
      color: #2b6cb0;
    }
  }
}

.buttons {
  margin-top: 1.5em;
  display: flex;
  gap: 1em;
}

button {
  background: #38a169;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 0.75em 1.25em;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #2f855a;
  }
}

.tags {
  display: flex;
  gap: 0.5em;
  justify-content: flex-start;
  max-width: 50%;
  flex-wrap: wrap;
  max-height: none;
  margin: 1em 0 0;

  a {
    text-decoration: none;
    color: black;
    background: #e2e8f0;
    padding: 0.25em 0.5em;
    font-size: 0.8rem;

    &:hover,
    &:focus {
      background: #cbd5e0;
    }
  }
}

.description {
  font-size: 0.9rem;
  max-width: 100%;
  overflow-wrap: anywhere;
}

.stats-chart {
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }

  h4 {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
  }
}

.views {
  margin: 4em 0 2em 0;
  text-align: center;

  span {
    background: #edf2f7;
    padding: 0.25em 0.5em;
    width: fit-content;
  }
}

.edit {
  margin-bottom: 1.5em;
}

.version-offline {
  // white dot
  &:before {
    content: "";
    width: .8em;
    height: .8em;
    border-radius: 50%;
    background-color: white;
    margin-right: .35em;
  }

  background-color: #982525 !important;
  font-size: .75em !important;

  display: flex;
  align-items: center;
}

.ip-chip-offline {
  button {
    background-color: #982525 !important;
  }
}
</style>