<template>
	<div>
		<Popup title="Vote">
			<form>
				<FormGroup
						v-model:parent_ref="username"
						label="Username"
						autocomplete="username"
						:check="(value) => {
                            return value.length >= fieldLimits.username.min && value.length <= fieldLimits.username.max;
                        }"
				/>
				<HCaptcha />
				<div class="form-group submit-group">
					<button type="submit" @click.prevent="vote">
						Vote
						<img alt="Login" src="~/assets/icons/right_arrow.svg" width="14" height="14">
					</button>
				</div>
			</form>
		</Popup>
	</div>
</template>

<script setup>
import { useUser } from "~/composables/useUser.js";
import { notify } from "@kyvg/vue3-notification";

const nuxtApp = useNuxtApp();

const { user } = useUser();

let localStorageUsername = null
try {
	localStorageUsername = localStorage.getItem("vote-username");
} catch (e) {
}

const username = ref(
	user.value ? user.value.username : localStorageUsername ? localStorageUsername : ""
);

const { setPopup } = usePopup();

async function vote() {
	const iframe = document.querySelector(".captcha iframe");
	if (!iframe) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	const captchaResponse = iframe.getAttribute("data-hcaptcha-response");

	if (!captchaResponse) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	localStorage.setItem("vote-username", username.value);

	notify({
		type: "info",
		title: "Voting..."
	})

	const server_id = useRoute().params.id.split("-")[1];

	const response = await $fetch("/api/server/" + server_id + "/vote", {
		method: "POST",
		body: {
			username: username.value,
			captcha: captchaResponse
		},
		ignoreResponseError: true
	});

	let closePopup = true;

	if (response.status) {
		notify({
			type: "success",
			title: "You have voted for this server!"
		})
	} else if (response.error) {
		if (response.error === "You have already voted for this server!") {
			const date = new Date();
			date.setUTCHours(0, 0, 0, 0);
			const localTimeString = new Intl.DateTimeFormat(undefined, {
				hour: "numeric",
				minute: "numeric"
			}).format(date);

			response.error += " You can vote again at " + localTimeString + "!";
		}

		notify({
			type: "error",
			title: response.error
		})
		if (response.error === "Please complete the captcha!") {
			closePopup = false;
		}
	} else {
		notify({
			type: "error",
			title: "Error occurred while voting!"
		})
	}

	if (closePopup) {
		setPopup(null);
	}
}
</script>